<template>
  <v-row class="app-invoice-preview" v-if="!loading || loadingPdf">
    <v-col cols="12" md="9" ref="carInvoice" class="fontUrbanist">
      <v-card v-if="true" class="card-invoice">
        <img
          src="@/assets/favicon.png"
          class="image-back"
          :style="
            $vuetify.breakpoint.mdAndUp ? 'width: 400px;' : 'width: 150px;'
          "
        />
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div
            class="
              invoice-header
              d-flex
              flex-wrap
              justify-space-between
              flex-column flex-sm-row
            "
          >
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <img
                  src="@/assets/Logo_negro.svg"
                  alt="Ridery"
                  class="logo-image"
                />
                <span class="text--primary ml-2 font-weight-bold text-xl">
                  <!-- Corporate -->
                </span>
              </div>
              <span class="d-block">Av. Andrés Bello, Caracas 1050</span>
              <span class="d-block">Distrito Capital, Venezuela</span>
              <span class="d-block"
                >+58 (123) 456 7891, +58 (876) 543 2198</span
              >
            </div>
            <!-- Right Content -->
            <div class="mb-8 mb-sm-0 text-right">
              <p>{{ $t("NON FISCAL") }}</p>
              <p class="font-weight-medium text-l text--primary">
                {{ $t("Invoice") }} #
                {{ trip.invoice_number.replace("TA PTE", "") }}
              </p>
              <span>
                <p class="mb-2 mt-5">
                  <span
                    >{{ $t("Date issued") }}:
                    {{ trip.created_at | moment("DD-MM-YYYY h:mm a") }}</span
                  >
                  <span class="font-weight-semibold"></span>
                </p>
                <p class="mb-2">
                  <span
                    >{{ $t("Date paid") }}:
                    {{ trip.created_at | moment("DD-MM-YYYY h:mm a") }}</span
                  >
                  <span class="font-weight-semibold"></span>
                </p>
              </span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div
            class="
              payment-details
              d-flex
              justify-space-between
              flex-wrap flex-column flex-sm-row
            "
          >
            <div class="mb-8 mb-sm-0">
              <p class="font-weight-semibold payment-details-header">
                {{ $t("Invoice to") }}:
              </p>
              <p class="mb-1">{{ name }}</p>
              <!-- <p class="mb-1">J-123456-7</p> -->
              <!-- <p class="mb-1"></p> -->
              <!-- <p class="mb-1"></p> -->
              <!-- <p class="mb-0"></p> -->
            </div>
            <!-- <div>
              <p class="font-weight-semibold payment-details-header">
                Bill To:
              </p>
              <table>
                <tr>
                  <td class="pe-6">Total Due:</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="pe-6">Bank Name:</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="pe-6">Country:</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="pe-6">IBAN:</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="pe-6">SWIFT Code:</td>
                  <td></td>
                </tr>
              </table>
            </div> -->
          </div>
        </v-card-text>

        <!-- Table viaje normal -->
        <v-simple-table
          class="purchased-items-table"
          v-if="!trip.is_multi_stop"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: left">{{ $t("TRIP ID") }}</th>
                <th style="text-align: left">{{ $t("USER") }}</th>
                <th style="text-align: left">{{ $t("DESCRIPTION") }}</th>
                <th style="text-align: left">{{ $t("SERVICIO") }}</th>
                <th style="text-align: left">{{ $t("ORIGIN ADDRESS") }}</th>
                <th style="text-align: left">{{ $t("DESTINATION ADDRESS") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ trip.unique_id }}
                </td>
                <td>
                  {{ trip.user_name }}
                </td>
                <td>
                  {{ trip.trip_comment }}
                </td>
                <td>
                  <span >
                    <span
                      class="
                        d-flex
                        flex-column
                        justify-center
                        align-center
                        mt-2
                      "
                      v-if="trip.serviceType"
                    >
                      <v-tooltip
                        top
                        content-class="bs-tooltip-top arrow"
                        :max-width="300"
                        bottom
                        :disabled="true"
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="
                              d-flex
                              flex-column
                              justify-center
                              align-center
                            "
                          >
                            <img
                              :src="`${bucketUrl}${trip.serviceType.type_image_url}`"
                              style="max-width: 60px"
                            />

                            <span class="fontUrbanist font-weight-bold">{{
                              trip.serviceType.typename
                            }}</span>
                          </span>
                        </template>
                        <span
                          class="d-flex flex-row justify-center align-center"
                        >
                          <v-icon
                            x-small
                            :style="
                              !$vuetify.theme.isDark &&
                              'color: #ffffff !important;'
                            "
                          >
                            {{ icons.mdiAccount }}
                          </v-icon>
                          <span class="ml-2">
                            {{ trip.serviceType.max_space }}
                          </span>
                          <span class="ml-2"> | </span>
                          <v-icon
                            x-small
                            class="ml-2"
                            :style="
                              !$vuetify.theme.isDark &&
                              'color: #ffffff !important;'
                            "
                          >
                            {{ icons.mdiBagSuitcase }}
                          </v-icon>
                          <span class="ml-2">
                            {{ trip.serviceType.baggages }}
                          </span>
                        </span>
                      </v-tooltip>
                    </span>
                    <span v-else>
                      <v-progress-circular indeterminate />
                    </span>
                  </span>
                </td>
                <td>
                  {{ trip.source_address }}
                </td>
                <td>
                  {{ trip.destination_address }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- tabla viaje multiparada -->
        <template v-if="trip.is_multi_stop">
          <v-simple-table
            class="purchased-items-table"
            v-if="trip.is_multi_stop"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="text-align: left">{{ $t("TRIP ID") }}</th>
                  <th style="text-align: left">{{ $t("USER") }}</th>
                  <th style="text-align: left">{{ $t("DESCRIPTION") }}</th>
                  <th style="text-align: left">{{ $t("SERVICIO") }}</th>
                  <th style="text-align: left">{{ $t("ORIGIN ADDRESS") }}</th>
                  <th style="text-align: left">{{ $t("PARADA FINAL") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ trip.unique_id }}
                  </td>
                  <td>
                    {{ trip.user_name }}
                  </td>
                <td>
                  {{ trip.trip_comment }}
                </td>
                  <td>
                    <span>
                      <span
                        class="
                          d-flex
                          flex-column
                          justify-center
                          align-center
                          mt-2
                        "
                        v-if="trip.serviceType"
                      >
                        <v-tooltip
                          top
                          content-class="bs-tooltip-top arrow"
                          :max-width="300"
                          bottom
                          :disabled="true"
                        >
                          <template #activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="
                                d-flex
                                flex-column
                                justify-center
                                align-center
                              "
                            >
                              <img
                                :src="`${bucketUrl}${trip.serviceType.type_image_url}`"
                                style="max-width: 60px"
                              />

                              <span class="fontUrbanist font-weight-bold">{{
                                trip.serviceType.typename
                              }}</span>
                            </span>
                          </template>
                          <span
                            class="d-flex flex-row justify-center align-center"
                          >
                            <v-icon
                              x-small
                              :style="
                                !$vuetify.theme.isDark &&
                                'color: #ffffff !important;'
                              "
                            >
                              {{ icons.mdiAccount }}
                            </v-icon>
                            <span class="ml-2">
                              {{ trip.serviceType.max_space }}
                            </span>
                            <span class="ml-2"> | </span>
                            <v-icon
                              x-small
                              class="ml-2"
                              :style="
                                !$vuetify.theme.isDark &&
                                'color: #ffffff !important;'
                              "
                            >
                              {{ icons.mdiBagSuitcase }}
                            </v-icon>
                            <span class="ml-2">
                              {{ trip.serviceType.baggages }}
                            </span>
                          </span>
                        </v-tooltip>
                      </span>
                      <span v-else>
                        <v-progress-circular indeterminate />
                      </span>
                    </span>
                  </td>
                  <td>
                    {{ trip.source_address }}
                  </td>
                  <td>
                    {{ trip.destination_address }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <br />
          <v-simple-table
            class="purchased-items-table"
            v-if="trip.is_multi_stop"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="text-align: left">{{ $t("PARADA") }}</th>
                  <th style="text-align: left">{{ $t("ORIGIN ADDRESS") }}</th>
                  <th style="text-align: left">{{ $t("DESTINATION ADDRESS") }}</th>
                  <th style="text-align: left">{{ $t("COSTO MULTIPARADA") }}</th>
                  <th style="text-align: left">{{ $t("RECARGO") }}</th>
                  <th style="text-align: left">{{ $t("TOTAL PARADA") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="tr-avoid-page-break"
                  v-for="(route, index) in trip.routes"
                  :key="route.index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ route.source_address }}
                  </td>
                  <td>
                    {{ route.destination_address }}
                  </td>
                  <td>
                    {{ route.base_estimated | currency }}
                  </td>
                  <td>
                    {{ route.multi_stops_fee | currency }}
                  </td>
                  <td>
                    {{ route.estimated_fare | currency }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div
            class="
              invoice-total
              d-flex
              justify-space-between
              flex-column flex-sm-row
            "
          >
            <div class="mb-2 mb-sm-0">
              <p class="mb-1">
                <span class="font-weight-semibold"
                  >{{ $t("Ridery's Corporate team") }}:
                </span>
                <br />
                <!-- <span>Manuel Martínez</span> -->
              </p>
              <p>{{ $t("Thank you for choosing us") }}</p>
            </div>
            <div>
              <!-- <table>
                <tr>
                  <td class="pe-16">Subtotal:</td>
                  <th :class="'text-right'">
                    {{ (trip.total * 0.84) | currency }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">{{ $t("Discount") }}:</td>
                  <th :class="'text-right'">$0</th>
                </tr>
                <tr>
                  <td class="pe-16">{{ $t("Tax") }} (16%):</td>
                  <th :class="'text-right'">
                    {{ (trip.total * 0.16) | currency }}
                  </th>
                </tr>
              </table> -->
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">Total:</td>
                  <th :class="'text-right'">
                    {{ trip.total_total | currency }}
                    <span
                      v-if="
                        trip.total_corporate_waiting_time > 0 &&
                        trip.corporate_waiting_time_cost > 0
                      "
                    >
                      <br />
                      {{ trip.total_corporate_waiting_time }} minutos({{
                        trip.corporate_waiting_time_cost | currency
                      }})
                    </span>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <p class="mb-0">
            <span class="font-weight-semibold">{{ $t("Note") }}: </span>
            <span>{{
              $t("This receipt does not represent a fiscal invoice")
            }}</span>
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <!-- <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            @click="$router.go(-1)"
          >
            <v-icon>
              {{ icons.mdiArrowLeft }}
            </v-icon>
            <span class="ml-2">Volver</span>
          </v-btn> -->
          <v-btn class="" color="secondary" block @click="printInvoice">
            {{ $t("Print") }}
          </v-btn>
          <v-btn class="mt-8" color="success" block @click="downloadInvoice">
            {{ $t("Download PDF") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiArrowLeft, mdiAccount, mdiBagSuitcase } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiAccount,
        mdiBagSuitcase,
      },
      trip: {},
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      loadingPdf: false,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    printInvoice() {
      window.print();
    },
    async downloadInvoice() {
      this.loadingPdf = true;
      this.setLoading(true);
      const isDarkMode = this.$vuetify.theme.dark;
      try {
        if (isDarkMode) {
          this.$vuetify.theme.dark = false;
        }

        setTimeout(async () => {
          const element = this.$refs.carInvoice;
          const clone = element.cloneNode(true);
          clone.className = "col-12 fontUrbanist";

          // Convert images to base64 URLs in the clone
          const images = clone.querySelectorAll("img");
          for (const img of images) {
            if (!img.src.startsWith("https://riderys3bucket.s3")) {
              continue;
            }
            try {
              const response = await axios.get(
                `${process.env.VUE_APP_STAGING}/corporate/proxy?url=${img.src}`,
                {
                  responseType: "blob",
                }
              );
              const blob = response.data;
              const reader = new FileReader();
              reader.onloadend = () => {
                img.src = reader.result;
              };
              reader.readAsDataURL(blob);
            } catch (error) {
              console.error("Error fetching image:", error);
            }
          }

          // Wait for all images to be converted to base64
          await new Promise((resolve) => setTimeout(resolve, 1000));

          const opt = {
            margin: 2,
            filename: `Ridery Corporate Panel - ${this.$t("Service Note")}.pdf`,
            image: { type: "jpeg", quality: 0.95 },
            html2canvas: { scale: 2, useCORS: true, allowTaint: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          html2pdf()
            .set(opt)
            .from(clone)
            .save()
            .finally(() => {
              this.setLoading(false);
              this.loadingPdf = false;
              if (isDarkMode) {
                this.$vuetify.theme.dark = true;
              }
            });
        }, 10);
      } catch (error) {
        this.setLoading(false);
        this.loadingPdf = false;
        if (isDarkMode) {
          this.$vuetify.theme.dark = true;
        }
      }
    },
    async getTrip() {
      if (this.$route.params.id) {
        let params = {
          search_value: this.$route.params.id,
          search_item: "unique_id",
          only_shipments: this.$route.query.only_shipments,
        };
        this.setLoading(true);
        try {
          const { data } = await axios.get("/trips", { params: params });
          if (!!data.detail.length !== 0) {
            this.trip = data.detail[0];
            if (this.$vuetify.lang.current === "es") {
              document.title =
                "Ridery Panel Corporativo: Nota de servicio" +
                " - " +
                this.trip.invoice_number;
            } else {
              document.title =
                "Ridery Corporate Panel: Service note" +
                " - " +
                this.trip.invoice_number;
            }
          } else {
            throw new Error("Error getting trips");
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
          this.$router.go(-1);
        }
        this.setLoading(false);
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", ["name"]),
  },
  created() {
    this.getTrip();
  },
};
</script>
<style lang="scss">
@import "~@core/preset/preset/apps/invoice.scss";

.logo-image {
  width: 120px;
}

.image-back {
  opacity: 0.05;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.card-invoice {
  position: relative;
}

@media print {
  .v-application {
    background: none !important;
  }

  .image-back {
    width: 400px !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.tr-avoid-page-break {
  page-break-inside: avoid;
}

// Create vuetify classes for html2pdf to work
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-sm-row {
  @media (min-width: 576px) {
    flex-direction: row !important;
  }
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0 !important;
  }
}

.text-right {
  text-align: right !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.text--primary {
  color: #000522 !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.tr-avoid-page-break {
  page-break-inside: avoid !important;
}

.w-full {
  width: 100% !important;
}

.fontUrbanist {
  font-family: "Urbanist" !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.justify-center {
  justify-content: center !important;
}

.align-center {
  align-items: center !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.pe-16 {
  padding-right: 16px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.border-bottom-thin {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14) !important;
}
</style>
